import { useState, useContext, useCallback, memo } from "react";

import PropTypes from "prop-types";
import StoreContext from "../../context/store";
import { GeoContext } from "../../context/GeoProvider";
import { useAddToCartWithLuxe } from "../../context/LuxeProvider";
import { gaCustomEvent, getAllUrlParams, isVariantOutOfStock } from "../../context/helpers";
import { useTracking } from "../../context/Tracking";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import { useALError, withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { applyIgPrices } from "../../helpers/intelligems";
import useDiscountsInfo from "../../hooks/useDiscountsInfo";

import { ALButton } from "../ALComponents";
import NotifyMe from "./NotifyMe";

import { Country } from "../../constants/Country";
import { ComponentType } from "../../constants/ComponentType";

function ButtonsAddToCart({
  // Main Product
  productHandle,
  images,
  isPreOrder,
  preOrderShipDate = null,
  variant,
  title,
  sku,
  category,
  productId,
  isEngravable,
  engraveDetails,
  isFinalSale,
  prices,
  // ====

  // Additional Products that are to be added by the add to cart action
  // (contains the same properties as the main product)
  additionalProducts = [],
  // ====

  // Additional properties
  componentType,
  searchObject,
  fallBackFunction,
  addProductCallback, // optional callback to be called after adding the product to the cart
}) {
  const context = useContext(StoreContext);
  const discountsInfo = useDiscountsInfo();
  const addToCartWithLuxe = useAddToCartWithLuxe();
  const { gePriceDetails } = useContext(GeoContext);
  const countryCode = gePriceDetails?.CountryCode;
  const currencyCode = gePriceDetails?.CurrencyCode;

  const igPrices = applyIgPrices(productId, variant, prices);

  const { trackAddToCartAfterSearch } = useTracking();
  const { sendReport, throwReport } = useALError();
  const dict = useLocalizedSentenceDict();

  const [errorMessage, setErrorMessage] = useState(null);

  const isSoldOut = isVariantOutOfStock(variant, countryCode);

  const handleAddToCart = useCallback(async () => {
    try {
      if (fallBackFunction) {
        fallBackFunction();
        return;
      }
      if (isEngravable && !engraveDetails) {
        setErrorMessage(dict.get("Please add your text"));
        return;
      }
      if (!variant) {
        setErrorMessage(dict.get("Please select a size"));
        return;
      }

      const dataProductsToAdd = [
        // Add the main product using the same process as the other products
        // TODO: create that object before the <ButtonsAddToCart /> component
        // so that ButtonsAddToCart is abstracted from the number of products (without notion of main product and bundle products)
        {
          title,
          productId,
          productHandle,
          sku,
          category,
          images,
          variant,
          prices: igPrices,
          isPreOrder,
          preOrderShipDate,
          isEngravable,
          engraveDetails,
          isFinalSale,
        },
        ...additionalProducts,
      ].map((productToAdd) => {
        const dataProductToAdd = {
          quantity: 1,
          attributes: [],
          variant: {
            id: productToAdd.variant.id || productToAdd.variant.shopifyId,
            sku: productToAdd.sku,
            product: {
              title: productToAdd.title,
              id: productToAdd.productId,
              handle: productToAdd.productHandle,
              productType: productToAdd.category,
              images: productToAdd.images,
            },
          },
        };

        try {
          // Price is only use for tracking purposes and can be undefined in some cases
          // We want to make sure ATC would still work if prices are not there
          dataProductToAdd.variant.price = Number(productToAdd.prices?.finalPrice);
          dataProductToAdd.variant.compareAtPrice = Number(productToAdd.prices?.compareAtPrice);
        } catch (error) {
          console.error("Failed setting price on ATC", error);
          sendReport(error, { name: "ButtonsAddToCart", priority: "P1" });
        }

        dataProductToAdd.attributesProperties = {
          isPreOrder,
          preOrderShipDate,
          isEngravable,
          engraveDetails,
          isUS: countryCode === Country.US,
          currencyCode,
          prices: igPrices,
          isFinalSale,
          igPrices,
          discountsInfo,
        };

        dataProductToAdd.trackingProperties = {
          shouldTrackAlgolia: !searchObject?.isSearch,
          source: componentType,
        };

        return dataProductToAdd;
      });

      await addToCartWithLuxe({ dataProductsToAdd });

      if (componentType === ComponentType.PDP) {
        const urlParams = getAllUrlParams();
        if (urlParams?.origin_content && urlParams.origin_content.startsWith("recommended-")) {
          const readableFormatOrigin = urlParams.origin_content
            .replace("recommended-", "")
            .split("-")
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(" ");
          gaCustomEvent(`Recommended Product - ${readableFormatOrigin}`, "atc", title);
          context.addTrackCartProduct({
            origin: urlParams.origin_content,
            productTitle: title,
            productId: productId.replace("gid://shopify/Product/", ""),
          });
        }
      }

      if (componentType === ComponentType.QUICKVIEW) {
        context.closeQuickView();
      }

      if (searchObject?.isSearch) {
        const { index, queryID, objectID } = searchObject;
        trackAddToCartAfterSearch(index, queryID, objectID, countryCode);
      }

      if (addProductCallback) {
        addProductCallback();
      }

      return;
    } catch (error) {
      console.error(error);
      throwReport(error);
    }
  }, [
    addToCartWithLuxe,
    additionalProducts,
    category,
    componentType,
    context,
    countryCode,
    currencyCode,
    dict,
    discountsInfo,
    engraveDetails,
    fallBackFunction,
    igPrices,
    images,
    isEngravable,
    isFinalSale,
    isPreOrder,
    preOrderShipDate,
    productHandle,
    productId,
    searchObject,
    sendReport,
    sku,
    throwReport,
    title,
    trackAddToCartAfterSearch,
    variant,
    addProductCallback,
  ]);

  if (isSoldOut) {
    return (
      <NotifyMe
        errorMessage={errorMessage}
        isPreOrder
        title={title}
        variant={variant}
        productId={productId}
        sendReport={sendReport}
      />
    );
  }

  return (
    <>
      {errorMessage ? <p className="add_to_cart_error al_p">{errorMessage}</p> : null}
      <div className={`add_to_cart add_to_cart__${componentType?.toLowerCase()}`}>
        <ALButton szie="large" fullWidth onClick={handleAddToCart}>
          {dict.get("ADD TO CART")}
        </ALButton>
      </div>
    </>
  );
}

ButtonsAddToCart.propTypes = {
  productHandle: PropTypes.string,
  isPreOrder: PropTypes.bool,
  images: PropTypes.arrayOf(PropTypes.object),
  preOrderShipDate: PropTypes.string,
  variant: PropTypes.shape({
    id: PropTypes.string,
    sku: PropTypes.string,
    title: PropTypes.string,
    availableForSale: PropTypes.bool,
    inventoryPolicy: PropTypes.string,
  }),
  title: PropTypes.string,
  sku: PropTypes.string,
  category: PropTypes.string,
  productId: PropTypes.string,
  isEngravable: PropTypes.bool,
  engraveDetails: PropTypes.shape({
    font: PropTypes.string,
    text: PropTypes.string,
  }),
  isFinalSale: PropTypes.bool,
  componentType: PropTypes.string,
  searchObject: PropTypes.shape({
    isSearch: PropTypes.bool,
    index: PropTypes.string,
    queryID: PropTypes.string,
    objectID: PropTypes.string,
  }),
  fallBackFunction: PropTypes.func,
  prices: PropTypes.shape({
    finalPrice: PropTypes.string,
    compareAtPrice: PropTypes.string,
  }),
  additionalProducts: PropTypes.arrayOf(
    PropTypes.shape({
      productHandle: PropTypes.string,
      images: PropTypes.arrayOf(PropTypes.object),
      isPreOrder: PropTypes.bool,
      preOrderShipDate: PropTypes.string,
      variant: PropTypes.shape({
        id: PropTypes.string,
        sku: PropTypes.string,
        title: PropTypes.string,
        availableForSale: PropTypes.bool,
        inventoryPolicy: PropTypes.string,
      }),
      title: PropTypes.string,
      sku: PropTypes.string,
      category: PropTypes.string,
      productId: PropTypes.string,
      isEngravable: PropTypes.bool,
      engraveDetails: PropTypes.shape({
        font: PropTypes.string,
        text: PropTypes.string,
      }),
      isFinalSale: PropTypes.bool,
      prices: PropTypes.shape({
        finalPrice: PropTypes.string,
        compareAtPrice: PropTypes.string,
      }),
    })
  ),
  addProductCallback: PropTypes.func,
};

export default withALErrorBoundary({
  name: "ButtonsAddToCart",
  priority: "P1",
})(memo(ButtonsAddToCart));
